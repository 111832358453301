<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="12">
          <form-v-select
            v-if="(['pusat']).includes(userLevel) || userType=='vendor'"
            ref="region"
            v-model="region"
            dataurl="region"
            label="Region"
            col-label="2"
            placeholder="Semua Region"
            :item_text="'name'"
            @input="changeRegion"
          />
        </b-col>
        <b-col cols="12">
          <form-v-select
            v-if="((['pusat', 'region']).includes(userLevel) || userType=='vendor')"
            ref="area"
            v-model="area"
            :dataurl="area_url"
            label="Area"
            col-label="2"
            placeholder="Semua Area"
            :item_text="'name'"
            @input="changeArea"
          />
        </b-col>
        <b-col cols="12">
          <form-v-select
            v-if="((['pusat', 'region', 'area']).includes(userLevel) || userType=='vendor')"
            ref="ulp"
            v-model="ulp"
            :dataurl="ulp_url"
            label="ULP"
            col-label="2"
            placeholder="Semua ULP"
            :item_text="'name'"
            @input="changeUlp"
          />
        </b-col>
        <b-col cols="12">
          <form-v-select
            v-if="((['pusat', 'region', 'area', 'ulp']).includes(userLevel) || userType=='vendor')"
            ref="kantorjaga"
            v-model="kantorjaga"
            :dataurl="kantorjaga_url"
            label="Kantor Jaga"
            col-label="2"
            placeholder="Semua Kantor Jaga"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="12">
          <form-v-select
            ref="project"
            v-model="project"
            dataurl="project"
            label="Project"
            col-label="2"
            placeholder="Semua Project"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="12">
          <form-v-select
            ref="variant"
            v-model="variant"
            dataurl="vehicle-variant"
            label="Model"
            col-label="2"
            placeholder="Semua Model"
            :item_text="'model'"
          />
        </b-col>
        <b-col cols="12">
          <form-v-select
            ref="manufacture"
            v-model="manufacture"
            dataurl="vehicle-manufacture"
            label="Pabrikan"
            col-label="2"
            placeholder="Semua Pabrikan"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="12" v-if="userType != 'vendor'" >
          <form-v-select
            v-if="userType!='vendor'"
            ref="vendor"
            v-model="vendor"
            dataurl="vendor"
            col-label="2"
            label="Vendor"
            placeholder="Semua Vendor"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="12">
          <b-form-group
            label-cols-md="2"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl+(userType=='vendor'?'?filter[contact_id]='+user.id:'')"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-add="false"
        :is-edit="false"
        :is-delete="false"
      >
        <template v-slot:buttonaddon>
          <div v-if="loading">
            <b-spinner small />
            Loading...
          </div>
          <div v-else>
            <b-button
              class="mr-1"
              size="sm"
              variant="success"
              @click="showFilter =!showFilter"
            >
              Filter
            </b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="warning"
              @click="exportExcel"
            >
              Export
            </b-button>
          </div>
        </template>
        <template #cell(branch_1)="data">
          {{ data.item.branch_level == 4 ? data.item.branch_4_name : (
              data.item.branch_level == 3 ? data.item.branch_3_name : (
                data.item.branch_level == 2 ? data.item.branch_2_name : (
                  data.item.branch_level == 1 ? data.item.branch_1_name : "-"
          ))) }}
        </template>
        <template #cell(branch_2)="data">
          {{ data.item.branch_level == 4 ? data.item.branch_3_name : (
              data.item.branch_level == 3 ? data.item.branch_2_name : (
                data.item.branch_level == 2 ? data.item.branch_1_name : "-"
          )) }}
        </template>
        <template #cell(branch_3)="data">
          {{ data.item.branch_level == 4 ? data.item.branch_2_name : (
              data.item.branch_level == 3 ? data.item.branch_1_name : "-"
          ) }}
        </template>
        <template #cell(branch_4)="data">
          {{ data.item.branch_level == 4 ? data.item.branch_1_name : "-" }}
        </template>
        <template #cell(project_name)="data">
          {{ data.item.project_name ? data.item.project_name : "-" }}
        </template>
        <template #cell(cost_rent)="data">
          {{ data.item.vehicle?(parseInt(data.item.vehicle.item.price)+parseInt(data.item.vehicle.item.price_maintenance)):0|numFormat }}
        </template>
        <template #cell(received_end_date)="data">
          {{ data.item.vehicle?formatDate(data.item.vehicle.received_date, data.item.vehicle?data.item.vehicle.item.duration:0):'' }}
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol,BSpinner } from 'bootstrap-vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import moment from 'moment'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormGroup,
    BSpinner,
    BFormSelect,
    BRow,
    BCol,
    FormVSelect
  },
  data() {
    return {
      userType:null,
      user:null,
      dataurl:"/vehicle",
      baseroute:"vehicles",
      title:"Semua Kendaraan",
      fields: [
        { key: 'branch_1', label: 'Region', sortable: true },
        { key: 'branch_2', label: 'Area', sortable: true },
        { key: 'branch_3', label: 'ULP', sortable: true },
        { key: 'branch_4', label: 'Kantor Jaga', sortable: true },
        { key: 'project_name', label: 'Project', sortable: true },
        { key: 'project_io_number', label: 'Nomor IO', sortable: true },
        { key: 'vehicle_number', label: 'Plat Nomor', sortable: true },
        { key: 'vehicle_model', label: 'Model', sortable: true },
        { key: 'vehicle_manufacture', label: 'Pabrikan', sortable: true },
        { key: 'vendor_name', label: 'Vendor', sortable: true },
        { key: 'spb_number', label: 'No SPB', sortable: true },
        { key: 'spb_contract_number', label: 'No Kontrak', sortable: true },
        { key: 'cost_rent', label: 'Harga Sewa', sortable: true },
        { key: 'vehicle.received_date', label: 'Tanggal Awal Penerimaan', sortable: true },
        { key: 'received_end_date', label: 'Tanggal Akhir Sewa', sortable: true },
      ],
      showFilter : false,
      filter :[],
      active:null,
      region:null,
      area:null,
      ulp:null,
      kantorjaga:null,
      project:null,
      variant:null,
      manufacture:null,
      vendor:null,
      loading:null,
      userLevel:null,
      branch_id: null,
      area_url: '',
      ulp_url: '',
      kantorjaga_url: '',
    }
  },
  created(){
    if(this.$store.state.profile.info){
      var info = this.$store.state.profile.info
      this.userType = info.contact_type.split('\\').pop().toLowerCase()
      this.user = this.$store.state.profile.info.contact
    }
  },
  mounted() {
    this.getData()
  },
  methods:{
    getData(){
      this.$http.get('auth/me').then( res => {
        var contact = res.data.contact
        if (contact.branch_id){
          this.branch_id = contact.branch_id
        }
        if (res.data.role.name.toLowerCase().includes('pusat')){
          this.userLevel = 'pusat'
        }else if (contact.kantorjaga){
          this.userLevel = 'kantorjaga'
        }else if (contact.ulp){
          this.userLevel = 'ulp'
          this.kantorjaga_url = 'service-guard?filter[parent_id]='+this.branch_id
        }else if (contact.area){
          this.userLevel = 'area'
          this.ulp_url = 'service-center?filter[parent_id]='+this.branch_id
        }else if (contact.region){
          this.userLevel = 'region'
          this.area_url = 'service-area?filter[parent_id]='+this.branch_id
        }
        console.log(this.userLevel);
      })
    },
    show(){
      this.filter['filter[region_id]']=this.region?this.region.id:null
      this.filter['filter[area_id]']=this.area?this.area.id:null
      this.filter['filter[ulp_id]']=this.ulp?this.ulp.id:null
      this.filter['filter[guard_id]']=this.kantorjaga?this.kantorjaga.id:null
      this.filter['filter[project_id]']=this.project?this.project.id:null
      this.filter['filter[variant_id]']=this.variant?this.variant.id:null
      this.filter['filter[vehicle_manufacture_id]']=this.manufacture?this.manufacture.id:null
      this.filter['filter[contact_id]']=this.vendor?this.vendor.id:null
      this.$refs.basetable.fetchData()
    },
    reset(){
      this.region = null
      this.area = null
      this.ulp = null
      this.kantorjaga = null
      this.project = null
      this.variant = null
      this.manufacture = null
      this.vendor = null
      this.show()
    },
    changeRegion(data){
      this.$refs.area.dataurl = 'service-area'+(this.region?'?filter[parent_id]='+this.region.id:'')
      this.area = null
      this.ulp = null
      this.kantorjaga = null
      this.$refs.area.getData()
    },
    changeArea(data){
      this.$refs.ulp.dataurl = 'service-center'+(this.area?'?filter[parent_id]='+this.area.id:'')
      this.ulp = null
      this.kantorjaga = null
      this.$refs.ulp.getData()
    },
    changeUlp(data){
      this.$refs.kantorjaga.dataurl = 'service-guard'+(this.ulp?'?filter[parent_id]='+this.ulp.id:'')
      this.$refs.kantorjaga.getData()
    },
    async exportExcel(){
      var params = {...this.$refs.basetable.filter}
      params["search"] = this.$refs.basetable.search
      params["sort[created_at]"] = "desc"
      this.loading = true
      this.$http.get('/vehicle/export', {params:params}).then(res => {
        window.open(res.data.filename)
        this.loading = false
      })
    },
    formatDate(date, duration){
      return date?moment(date).add(duration, 'M').format('Y-MM-DD'):''
    }
  }
}
</script>
<style lang="">

</style>
